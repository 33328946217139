import { isPlatform } from '@ionic/react';
import { AuthService as AuthServiceAppAuth } from 'ionic-appauth';
import { CapacitorStorage } from 'ionic-appauth/lib/capacitor';

import env from '@common/env';

import authBrowser from './AuthBrowser';
import { AuthRequestor } from './AuthRequestor';
import { refreshToken } from './service-helpers';

let authService: null | AuthService = null;

export class AuthService extends AuthServiceAppAuth {
  async initWithRefresh() {
    await refreshToken(this.configuration, this.authConfig);
    this.init();
  }
}

export function getAuthService() {
  if (authService) {
    return authService;
  }
  const authRequester = new AuthRequestor();
  authService = new AuthService(authBrowser, new CapacitorStorage(), authRequester);

  const client_id = env('AUTH_CLIENT_ID');
  const server_host = env('AUTH_SERVER_HOST');

  const redirect_url = isPlatform('capacitor')
    ? `${env('CAPACITOR_LOCAL_SCHEMA')}://login/callback`
    : window.location.origin + '/login/callback';

  const end_session_redirect_url = isPlatform('capacitor')
    ? `${env('CAPACITOR_LOCAL_SCHEMA')}://logout/callback`
    : window.location.origin + '/logout/callback';

  const scopes = env('AUTH_SCOPES');

  authService.authConfig = {
    client_id,
    server_host,
    redirect_url,
    end_session_redirect_url,
    scopes,
    pkce: true,
  };

  authService.initWithRefresh();

  return authService;
}
