import { FC } from 'react';

import { styled } from '@stitches/react';

import { TransformStitchesToSparky } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';
import { Text } from '../Text/Text';

export const Circle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: '$backgroundDark',
  color: '$backgroundPrimary',
  variants: {
    size: {
      XS: {
        width: '2.4rem',
        height: '2.4rem',
        [`& ${Text}`]: {
          fontSize: '$BodyS',
        },
      },
      S: {
        width: '3.2rem',
        height: '3.2rem',
        [`& ${Text}`]: {
          fontSize: '$BodyM',
        },
      },
      M: {
        width: '6rem',
        height: '6rem',
        [`& ${Text}`]: {
          fontSize: '$S',
        },
      },
      L: {
        width: '8rem',
        height: '8rem',
        [`& ${Text}`]: {
          fontSize: '$M',
        },
      },
    },
    emphasis: {
      low: {
        backgroundColor: '$neutral500',
      },
      high: {
        backgroundColor: '$backgroundDark',
      },
    },
  },
});

type IndexCircleVariants = TransformStitchesToSparky<typeof Circle>;

export const IndexCircle: FC<React.PropsWithChildren & IndexCircleVariants> = ({ children, ...variantProps }) => {
  const { emphasis = 'high', size = 'XS' } = extractVariantProps(variantProps);
  return (
    <Circle aria-hidden="true" emphasis={emphasis} size={size}>
      <Text align={'center'} weight={'bold'}>
        {children}
      </Text>
    </Circle>
  );
};
