import { useEffect, useState } from 'react';

import { App, AppState } from '@capacitor/app';
import { TokenResponse } from '@openid/appauth';
import { AuthActions, IAuthAction } from 'ionic-appauth';
import { merge, ReplaySubject, timer } from 'rxjs';
import { switchMap, filter, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

import logger from '@common/log';

import { getAuthService } from '../service';
export interface TokenData {
  accessToken: string;
  customerId: string;
}

interface UserInfo {
  sub: string;
  customerId: string;
}

export const refreshInterval$ = new ReplaySubject<number | undefined>(0);
export const appIsActive$ = new ReplaySubject<boolean>();

export function useAuthToken() {
  const [tokenData, setTokenData] = useState<TokenData | undefined | null>();
  useEffect(() => {
    const authService = getAuthService();

    async function processToken(token: TokenResponse) {
      if (token.isValid()) {
        await authService.loadUserInfo();

        if (token.expiresIn) {
          // (seconds to ms) and use 90% of the interval to prevent sending out the request to late
          refreshInterval$.next(token.expiresIn * 1000 * 0.9);
        }
      } else {
        return authService.getValidToken();
      }
    }

    function updateTokenState(token: TokenResponse, userInfo: UserInfo) {
      if (token && userInfo) {
        setTokenData({
          accessToken: token?.accessToken,
          customerId: userInfo?.customerId,
        });
      } else {
        setTokenData(null);
      }
    }

    App.addListener('appStateChange', (state: AppState) => {
      appIsActive$.next(state.isActive);
    });

    const subscription = merge(
      authService.initComplete$,
      appIsActive$.pipe(
        distinctUntilChanged(),
        filter(active => active),
        switchMap(() => authService.initComplete$),
      ),
      refreshInterval$.pipe(
        switchMap(interval => {
          filter(interval => interval !== undefined);
          return timer(interval as number).pipe(
            switchMap(() => {
              return authService.initComplete$;
            }),
          );
        }),
      ),
    )
      .pipe(
        filter(complete => complete),
        switchMap(() => {
          return authService.token$;
        }),
      )
      .subscribe(token => {
        if (token) {
          processToken(token).catch(error => {
            logger.error('d07IXc', 'Token retrieval failed', error);
          });
        }
      });

    function handleTokenUserData(token: TokenResponse, userInfo: UserInfo) {
      if (!token) {
        setTokenData(null);
        return;
      }
      updateTokenState(token, userInfo);
    }

    const tokenSubscription = authService.initComplete$
      .pipe(
        filter(initComplete => initComplete === true),
        switchMap(() => {
          return authService.token$.pipe(distinctUntilChanged((prev, next) => prev?.accessToken === next?.accessToken));
        }),
      )
      .pipe(withLatestFrom(authService.user$.pipe(distinctUntilChanged((prev, next) => prev?.sub === next?.sub))))
      .subscribe(([token, userInfo]: [TokenResponse, UserInfo]) => {
        handleTokenUserData(token, userInfo);
      });

    const userSubscription = authService.initComplete$
      .pipe(
        filter(initComplete => initComplete === true),
        switchMap(() => {
          return authService.user$.pipe(distinctUntilChanged((prev, next) => prev?.sub === next?.sub));
        }),
      )
      .pipe(
        withLatestFrom(
          authService.token$.pipe(distinctUntilChanged((prev, next) => prev?.accessToken === next?.accessToken)),
        ),
      )
      .subscribe(([userInfo, token]: [UserInfo, TokenResponse]) => {
        handleTokenUserData(token, userInfo);
      });

    const eventSubscription = authService.events$.subscribe((event: IAuthAction) => {
      switch (event.action) {
        case AuthActions.LoadUserInfoFailed:
          logger.error('4t34GD', `Login error: ${AuthActions.LoadUserInfoFailed}`);
          break;
        case AuthActions.RevokeTokensFailed:
          logger.error('vTz7jv', `Login error: ${AuthActions.RevokeTokensFailed}`);
          break;
        case AuthActions.RefreshFailed:
          logger.error('dVWS4L', `Login error: ${AuthActions.RefreshFailed}`);
          break;
        case AuthActions.LoadTokenFromStorageFailed:
          logger.error('RJ0bOO', `Login error: ${AuthActions.LoadTokenFromStorageFailed}`);
          break;
      }
    });

    return () => {
      tokenSubscription.unsubscribe();
      userSubscription.unsubscribe();
      eventSubscription.unsubscribe();
      subscription.unsubscribe();
    };
  }, []);

  return tokenData;
}
