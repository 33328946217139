import React from 'react';

import {
  castToNumericLiteral,
  correctCaretPosition,
  formatValue,
  getBounds,
  getHighest,
  getLowest,
  getParts,
  formattedToString,
  setCaretPosition,
} from './utils';
import { useI18nTranslations } from '../providers/i18n';
import { useLocale } from '../providers/locale';

export type ChangeHandler = Pick<React.ChangeEvent<HTMLInputElement>, 'target' | 'type'>;

export interface FormatOptions {
  currency?: 'EUR';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  unit?: 'm2' | 'm3' | 'kWh' | '%' | 'kW';
  useGrouping?: boolean;
}
export interface Props {
  value?: number | string;
  defaultValue?: number | string;
  min?: number | string;
  max?: number | string;
  step?: number | string;
  onChange?({ target, type }: ChangeHandler): void;
  formatOptions?: FormatOptions;
}

/**
 * The maximum amount of decimals is the minimum by default. If you want to allow more than the minimum, you have to pass the maximumFractionDigits prop
 */
const defaultFormatOptions = {
  minimumFractionDigits: 0,
  useGrouping: true,
};

export function useNumberInput(
  {
    step: rawStep,
    min: rawMin,
    max: rawMax,
    defaultValue,
    onChange: passedOnChange,
    formatOptions: passedOptions = defaultFormatOptions,
  }: Props,
  mockRef: React.MutableRefObject<HTMLInputElement | null>,
) {
  const locale = useLocale();

  /**
   * Make sure that `min`, `max` and `step` are `Number` because we need to do calculations
   * We can memoize these values, but let's see how it goes
   */
  const min = rawMin !== undefined ? parseInt(rawMin.toString(), 10) : undefined;
  const max = rawMax !== undefined ? parseInt(rawMax.toString(), 10) : undefined;
  const step = rawStep ? parseFloat(rawStep.toString()) : 1;

  // Combine default optons with passed options
  const { unit, ...formatOptions } = {
    ...defaultFormatOptions,
    ...passedOptions,
    style: passedOptions.currency ? 'currency' : undefined,
  };

  // Set initial value based on the supplied props
  const initialValue = defaultValue ?? '';

  // The value will always be non-formatted verson of the value (e.g. '10000.00' or '.11')
  const [value, setValue] = React.useState<string>(initialValue.toString());

  // Format the value including the unit
  const formattedValue =
    formatValue({ locale, options: formatOptions, value }) + (value !== '' && unit ? ` ${unit}` : '');

  // A plain numeric literal value
  const plainValue = castToNumericLiteral(value);

  // We want to convey good message to users with assistive technology
  const labels = useI18nTranslations();
  const [ariaMessage, setAriaMessage] = React.useState<number | string | null>(initialValue);

  /**
   * Manually trigger onChange with a mocked native ref when the value changes
   */
  React.useEffect(() => {
    if (passedOnChange && mockRef?.current && mockRef.current.value !== value) {
      // If the value ends with a decimal point, we give back the integer with required decimals
      const requiredDecimals = formatOptions.minimumFractionDigits;
      mockRef.current.value = value.endsWith('.') ? Number(value).toFixed(requiredDecimals) : value;

      passedOnChange({ target: mockRef.current, type: 'change' });
    }
  }, [value, plainValue, passedOnChange, formatOptions?.minimumFractionDigits, mockRef]);

  /**
   * Set the translated ARIA message based on the value
   */
  React.useEffect(() => {
    if (!plainValue?.length) {
      setAriaMessage(labels['noValue']);
      return;
    }

    if (max && Number(plainValue) >= max) {
      setAriaMessage(`${max}, ${labels['maxReached']}`);
      return;
    }

    if (min && Number(plainValue) <= min) {
      setAriaMessage(`${min}, ${labels['minReached']}`);
      return;
    }

    setAriaMessage(null);
  }, [formattedValue, plainValue, labels, max, min]);

  /**
   * The decrement function which subtracts one `step` of the current value
   */
  const decrement = React.useCallback(() => {
    setValue(stateValue => {
      const nr = +castToNumericLiteral(stateValue);
      const newValue = getHighest(getLowest(nr - step, min), max);
      return newValue.toString();
    });
  }, [min, step, max]);

  /**
   * The increment function which adds one `step` to the current value
   */
  const increment = React.useCallback(() => {
    setValue(stateValue => {
      const nr = +castToNumericLiteral(stateValue);
      const newValue = getHighest(getLowest(nr + step, min), max);
      return newValue.toString();
    });
  }, [max, step, min]);

  /**
   * Round function to only allow stepped numbers within the boundaries of `min` and `max`
   * */
  const snapToStep = React.useCallback(() => {
    setValue(stateValue => {
      if (stateValue === '') return '';

      const nr = +castToNumericLiteral(stateValue);

      // Only allow stepped numbers
      const roundedValue = nr > step ? Math.ceil(nr / step) * step : nr;
      // Round if below min
      const minValue = getLowest(roundedValue, min);
      // Round if above max
      const maxValue = getHighest(minValue, max);

      return maxValue.toString();
    });
  }, [max, step, min]);

  /**
   * Sets the value of the input
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;
    const changeValue = el.value;

    // Support an empty string for an empty input
    if (changeValue === '') {
      setValue('');
      return;
    }

    const parsed = formattedToString({
      formattedValue: changeValue,
      replaceInteger: value.startsWith('.'),
      options: { ...formatOptions, unit },
      locale,
    });

    const [integer, decimals] = parsed.split('.');

    // When we dont have grouping or decimals we can just set the number directly
    const maxDigits = formatOptions.maximumFractionDigits || formatOptions.minimumFractionDigits;
    const allowDigits = !!maxDigits;
    const hasValueDecorations = formatOptions.currency || unit || formatOptions.useGrouping;
    // Set the value as is when we don't have value decorations
    if (!allowDigits && !hasValueDecorations) {
      setValue(integer);
      return;
    }

    // Truncate the decimals to prevent rounding (toFixed seems to round) when the last invisible decimal is high
    const truncDecimals = decimals?.slice(0, maxDigits);

    // The value we will format at the end of the onChange
    const truncatedParsed = truncDecimals ? [integer, truncDecimals].join('.') : parsed;

    // Get the current caret position (this accounts for the newest keydown already)
    const curPos = Number(el.selectionStart);

    // When we go from 100 to 1000 the formatting goes from 100 -> 1.000 (these are called groups).
    // Comparing the amount of groups will give us the new caret position
    const oldParts = getParts({ value, options: formatOptions, locale });
    const oldGroupsCount = oldParts.filter(part => part.type === 'group').length;

    // Get the parts of the new value
    const newParts = getParts({ value: truncatedParsed, options: formatOptions, locale });
    const newGroupsCount = newParts.filter(part => part.type === 'group').length;

    // Check the difference in groups
    const groupDiff = newGroupsCount - oldGroupsCount;

    // Set the caret position behind the prefix and modify it with the difference in groups
    const { start } = getBounds({ options: formatOptions, locale });
    const newPos = formattedValue === '' ? curPos + groupDiff + start : curPos + groupDiff;

    // Set the value
    setValue(truncatedParsed);

    // Manually move the caret
    window.requestAnimationFrame(() => {
      setCaretPosition(el, Math.max(0, newPos));
      correctCaretPosition({
        options: { ...formatOptions, unit },
        locale,
        expectedCaretPosition: newPos,
        event: e,
      });
    });
  };

  /**
   * Keyboard support for the numeric text input
   */
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const el = e.currentTarget;
      const curPos = Number(el.selectionStart);

      const parts = getParts({ value: '1.11', locale, options: formatOptions });
      const decimalPoint = parts.find(p => p.type === 'decimal')?.value || ',';
      const separatorIndex = el.value.indexOf(decimalPoint);
      const hasSelection = curPos !== Number(el.selectionEnd);

      // Check if the separator is before or after the caret because we want to skip the comma on every keydown.
      const decimalRequired = Number(formatOptions?.minimumFractionDigits) > 0;
      const isNearDecimal = separatorIndex > -1 && curPos === separatorIndex + Number(e.key === 'Backspace');

      // Ignore the decimal sign when user presses Delete, and move the caret past the decimal sign because we dont want to remove the decimal sign
      if (e.key === 'Delete' && isNearDecimal && decimalRequired) {
        e.preventDefault();
        setCaretPosition(el, curPos + 1);
        return;
      }

      // Same as Delete but move the caret -before- the decimal sign
      if (e.key === 'Backspace' && isNearDecimal && decimalRequired && !hasSelection) {
        e.preventDefault();
        setCaretPosition(el, curPos - 1);
        return;
      }

      // Keys that are not the following should be completely ignored
      const allowedKeys = [
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        'Delete',
        'Tab',
        'Enter',
        'Shift',
        'Control',
        'Meta',
        '.',
        ',',
      ];

      const isValidKey = /^[0-9]$/i.test(e.key) || allowedKeys.includes(e.key);
      // Allow the user to use the keyboard shortcuts for select/copy/paste
      const isModifiedKey = e.metaKey || e.ctrlKey;

      if (!isValidKey && !isModifiedKey) {
        e.preventDefault();
      }

      // Add accessible keyboard support that changes the value for the input
      switch (e.key) {
        case 'ArrowUp':
        case 'Up':
          increment();
          break;

        case 'ArrowDown':
        case 'Down':
          decrement();
          break;

        case 'Home':
          if (min !== undefined) setValue(min.toString());
          break;

        case 'End':
          if (max !== undefined) setValue(max.toString());
          break;
      }

      // Because state was set in the switch case, the caret moves to the end of the string
      // we need to manually set and correct the caret position for keys that don't trigger the onChange
      window.requestAnimationFrame(() => {
        let expectedCaretPosition = curPos;

        // We only predict keys that dont change the value, because those will be corrected in the onChange
        if (e.key === 'ArrowLeft') expectedCaretPosition = curPos - 1;
        if (e.key === 'ArrowRight') expectedCaretPosition = curPos + 1;

        // We only correct keys that don't trigger the onChange
        if (['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
          setCaretPosition(el, expectedCaretPosition);
          correctCaretPosition({
            options: { ...formatOptions, unit },
            locale,
            expectedCaretPosition,
            event: e,
          });
        }
      });
    },

    [decrement, increment, max, min, formatOptions, locale, unit],
  );

  const onFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const el = e.currentTarget;

      // Because of a bug in Webkit we need to wrap this function in a setTimeout https://bugs.chromium.org/p/chromium/issues/detail?id=779328
      setTimeout(() => {
        correctCaretPosition({
          options: { ...formatOptions, unit },
          locale,
          expectedCaretPosition: Number(el.selectionStart),
          event: e,
        });
      }, 0);
    },
    [formatOptions, locale, unit],
  );

  const onMouseUp = React.useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      correctCaretPosition({
        options: { ...formatOptions, unit },
        locale,
        expectedCaretPosition: Number(e.currentTarget.selectionStart),
        event: e,
      });
    },
    [formatOptions, locale, unit],
  );

  return {
    /**
     * Numeric string literal version of the value
     */
    value: plainValue,
    /**
     * The formatted value
     */
    formattedValue,
    /**
     * The increment function which adds one `step` to the current value.
     */
    increment,
    /**
     * The decrement function which subtracts one `step` of the current value.
     */
    decrement,
    /**
     * A function rounding the value to only allow "stepped" numbers within the boundaries of `min` and `max`.
     */
    snapToStep,
    /**
     * The `onChange` function to control the value of the hook.
     */
    onChange,
    /**
     * Function that handles keyboard interaction with the input field to comply with spinbutton ARIA pattern.5
     */
    onKeyDown,
    onFocus,
    onMouseUp,
    min,
    max,
    step,
    /**
     * A short text that should be used to convey an accessible message describing the state of the value.
     * The message is used to alert the value, if there is no value or if the min or max value have been reached.
     */
    ariaMessage: ariaMessage || formattedValue || value,
  };
}
