import React, { FC } from 'react';

import { styled } from '../../stitches.config';
import { StitchesVariants } from '../../types';
import { extractVariantProps } from '../../util/css/stitches';

const StyledBadge = styled('span', {
  borderRadius: '$round',
  fontWeight: '$bodyBold',
  paddingX: '$3',
  paddingY: '$1',

  variants: {
    color: {
      alpha: {
        background: '$feedbackBackgroundSuccess',
        color: '$textOnBackgroundVarFive',
      },
    },

    size: {
      S: {
        typography: '$bodyXS',
      },
      M: {
        typography: '$bodyS',
      },
    },
  },
});

type ColorTypes = StitchesVariants<typeof StyledBadge>['color'];
type SizeTypes = StitchesVariants<typeof StyledBadge>['size'];

interface Props {
  color?: ColorTypes;
  size?: SizeTypes;
  children: string;
  className?: never;
}

const stitchesClassName = 'sparky-badge';

export const Badge: FC<Props> = ({ children, color = 'alpha', size = 'S', className = '' }) => {
  const variantProps = extractVariantProps({ color, size });

  return (
    <StyledBadge {...variantProps} className={`${stitchesClassName} ${className}`}>
      {children}
    </StyledBadge>
  );
};

Badge.toString = () => `.${stitchesClassName}`;
