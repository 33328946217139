import React, { FC } from 'react';

import { useI18nTranslations } from '../../providers/i18n';
import { styled } from '../../stitches.config';
import { Box } from '../Box/Box';
import { PageGrid } from '../PageGrid/PageGrid';
import { Stack } from '../Stack/Stack';
import { Text } from '../Text/Text';

const StyledStack = styled(Stack, {
  height: '100px',
});

type CompoundHeaderProps = FC<{
  children: React.ReactNode;
}> & {
  Main: typeof Main;
  Top: typeof Top;
  Sub: typeof Sub;
};

export const DesktopHeader: CompoundHeaderProps = ({ children }) => <>{children}</>;

type MainProps = {
  children: React.ReactNode;
  /** Logo that links back to the homepage, which should have an accessible description. */
  logoLink: React.ReactNode;
  languageSelector?: React.ReactNode;
  profileLink?: React.ReactNode;
  isOpenDomain?: boolean;
};

const StyledMain = styled('div', {
  variants: {
    isOpenDomain: {
      true: {
        background: '$backgroundPrimary',
      },
      false: {
        background: '$navBackgroundPrimary',
      },
    },
  },
});

const Main: FC<React.PropsWithChildren<MainProps>> = ({
  children,
  logoLink,
  languageSelector,
  profileLink,
  isOpenDomain,
}) => {
  const { languageAndProfile } = useI18nTranslations();

  return (
    <StyledMain isOpenDomain={isOpenDomain}>
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
          <StyledStack direction="row" alignY="center">
            <Stack.Item grow>
              <Stack direction="row" alignY="center">
                {logoLink}
                <Stack.Item grow>
                  <Box paddingX="5">{children}</Box>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack as="nav" direction="row" alignY="center" gap="4" aria-label={languageAndProfile}>
              {languageSelector}
              {profileLink}
            </Stack>
          </StyledStack>
        </PageGrid.Item>
      </PageGrid>
    </StyledMain>
  );
};

type TopProps = {
  children: React.ReactNode;
};

const StyledTop = styled(Box, {
  background: '$navBackgroundPrimary',
  borderBottom: '$borderWidths$navDividerHeight solid $borderDividerLowEmphasis',
});

const Top: FC<React.PropsWithChildren<TopProps>> = ({ children }) => {
  const { domainNavigation } = useI18nTranslations();
  return (
    <StyledTop paddingTop="3" paddingBottom="1">
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
          <nav aria-label={domainNavigation}>
            <Stack direction="row" gap="5" alignX="end" alignY="center" as="ul">
              {children}
            </Stack>
          </nav>
        </PageGrid.Item>
      </PageGrid>
    </StyledTop>
  );
};

const StyledRootArrow = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: -0.2,
});

const StyledSVGGroup = styled('g', {
  fill: '$navBackgroundTertiary',
});

const RootArrow: FC = () => (
  <StyledRootArrow>
    <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="100%" viewBox="0 0 18 60">
      <StyledSVGGroup fill="none" fillRule="evenodd">
        <path
          d="M209 0l16.235 23.743c2.28 3.333 2.33 7.71.129 11.096L209 60V0z"
          transform="translate(-209 -100) translate(0 17) translate(0 83)"
        />
      </StyledSVGGroup>
    </svg>
  </StyledRootArrow>
);

const SubHeaderBackground = styled('div', {
  background: 'linear-gradient(to right, $navBackgroundTertiary 50%, $navBackgroundSecondary 50%)',
  backgroundColor: '$navBackgroundTertiary',
});

const StyledTitleContainer = styled(Stack, {
  background: '$navBackgroundTertiary',
  color: '$navTextLabel',
  height: '100%',
});

const LinkContainer = styled(Stack, {
  backgroundColor: '$navBackgroundSecondary',
  flexGrow: 1,
  position: 'relative',
});

type SubProps = {
  children: React.ReactNode;
  title: React.ReactNode;
};

const Sub: FC<React.PropsWithChildren<SubProps>> = ({ children, title }) => {
  const { secondaryNavigation } = useI18nTranslations();

  return (
    <SubHeaderBackground>
      <PageGrid>
        <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
          <Stack direction="row" alignY="center">
            <StyledTitleContainer alignY="center">
              <Text weight="bold" size="BodyL" whiteSpace="nowrap">
                {title}
              </Text>
            </StyledTitleContainer>
            <LinkContainer direction="row" alignY="center">
              <RootArrow />
              <nav aria-label={secondaryNavigation}>
                <Box padding="5" paddingLeft="10">
                  <Stack as="ul" alignY="center" direction="row" gap="5" wrap>
                    {children}
                  </Stack>
                </Box>
              </nav>
            </LinkContainer>
          </Stack>
        </PageGrid.Item>
      </PageGrid>
    </SubHeaderBackground>
  );
};

DesktopHeader.Top = Top;
DesktopHeader.Main = Main;
DesktopHeader.Sub = Sub;

StyledMain.displayName = 'styled(Main)';
StyledStack.displayName = 'styled(Stack)';
StyledTop.displayName = 'styled(Box)';
StyledRootArrow.displayName = 'styled(RootArrow)';
StyledSVGGroup.displayName = 'styled(SVGGroup)';
SubHeaderBackground.displayName = 'styled(SubHeaderBackground)';
StyledTitleContainer.displayName = 'styled(TitleContainer)';
RootArrow.displayName = 'RootArrow';
Sub.displayName = 'Sub';
DesktopHeader.displayName = 'DeskthopHeader';
DesktopHeader.Top.displayName = 'DesktopHeader.Top';
DesktopHeader.Main.displayName = 'DesktopHeader.Main';
DesktopHeader.Sub.displayName = 'DesktopHeader.Sub';
