import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { SEVERITY_LEVELS, serializeError } from './utils';
import env from '../env';
import { once } from '../fn/once';
import logger from '../log';
import { LogContext } from '../log/types';

const connectionString = env('APPINSIGHTS_CONNECTIONSTRING');
const role = env('CONTAINER');

// eslint-disable-next-line no-console
const warnOnce = once(console.warn);

function isExcludedTelementryItem(envelope: ITelemetryItem): boolean {
  if (envelope.baseType === 'RemoteDependencyData' && envelope.baseData) {
    const baseData = envelope.baseData;

    const isAjaxRequest = baseData.type === 'Ajax' || baseData.type === 'Fetch';
    const isStatusCodeZero = +baseData.responseCode === 0;
    if (isAjaxRequest && isStatusCodeZero) {
      return true;
    }

    const isAdCallsGetRequest = baseData.target?.startsWith('https://api.adcalls.nl/API/v3/session/update-session-data?cookie=undefined');
    const isStatusCode400 = +baseData.responseCode === 400;
    if (isAdCallsGetRequest && isStatusCode400) {
      return true;
    }
  }

  return false;
}

const telemetryInitializer = (envelope: ITelemetryItem): boolean => {
  envelope.tags = envelope.tags ?? [];
  envelope.tags['ai.cloud.role'] = role;

  return !isExcludedTelementryItem(envelope);
};

let appInsights: ApplicationInsights | undefined;

export const getClient = () => {
  if (connectionString && !appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        correlationHeaderDomains: ['enecogroup.com'],
        // Disable user tracking
        enableAutoRouteTracking: false,
        autoTrackPageVisitTime: false,
        // Track fetch requests + headers
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        // Add correlation data (end-to-end transactions)
        enableCorsCorrelation: true,
        disableCorrelationHeaders: false,
        // Exceptions and unhandled promise rejections
        disableExceptionTracking: false,
        enableUnhandledPromiseRejectionTracking: true,
        disablePageUnloadEvents: ['unload'],
      },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);

    logger.dev('Application Insights enabled (browser)');
  }

  return appInsights;
};

export const trackTrace = ({ uuid, message, context, error, level }: LogContext): void => {
  const client = getClient();

  if (!client) {
    warnOnce('Application Insights client not available.');
    return;
  }

  client.trackTrace(
    {
      message,
      severityLevel: SEVERITY_LEVELS[level],
    },
    Object.assign({ uuid, error: serializeError(error) }, context),
  );
};

export const trackException = ({ uuid, message, context, error, level }: LogContext): void => {
  const client = getClient();

  if (!client) {
    warnOnce('Application Insights client not available.');
    return;
  }

  const exception = error ?? new Error(`${message} (${uuid} is error-less)`);

  client.trackException(
    {
      id: uuid, // TODO Does not show up in AI like this
      exception,
      severityLevel: SEVERITY_LEVELS[level],
    },
    Object.assign({ uuid, message }, context),
  );
};
